import React from 'react'

const MonitorTaskContainer = Loader.loadBusinessComponent('MonitorTasks', 'MonitorTaskContainer')

const PrivateNetMonitorTask = () => (
  <MonitorTaskContainer 
    libType={4}
    taskType='101504'
    currentMenu='privateNetTaskView'
    moduleName='privateNetTaskManage'
  />
)

export default PrivateNetMonitorTask

